<template>
    <div class=" flex px-4 pt-2 border border-dashed py-1 my-1" v-show="hasContent">
        <span class=" font-bold">公告：</span>
        <div v-html="conetent" class=" text-black">
        </div>
    </div>
</template>
<script setup lang="ts">

const { data: notification } = await useFetch<{ data: any }>("/blog/notification/first", { parseResponse: JSON.parse })
const conetent = (unref(notification)?.data?.content || "")
// 是否有实际内容
const hasContent = computed(() => {
    return conetent.replace(/<.*?>/g, '').length > 0
})
</script>